<template>
  <div>
    <div class="img1">
      <div class="h1">文案专属定制</div>
      <div class="img_tip">全科定制，满足您全部所求</div>
    </div>
    <!-- <img src="@/assets/image/icon66.png" alt=""  /> -->
    <div class="contain">
      <div class="h1">
        <div class="h1_top">
          <img src="@/assets/image/icon67.png" alt="" />
          <div>文案专属定制</div>
          <img src="@/assets/image/icon68.png" alt="" />
        </div>
        <img src="@/assets/image/icon69.png" alt="" class="h1_bottom" />
      </div>
      <div class="list">
        <div class="list_item" v-for="(item, index) in list" :key="index">
          <div class="num">{{ index + 1 }}</div>
          <div class="text">{{ item }}</div>
        </div>
      </div>
      <div class="btn" @click="show = !show">
        <img src="@/assets/image/icon70.png" alt="" />
        联系客服
        <div style="position: absolute;left: 380px;">
          <telToast :show="show" v-if="show" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import telToast from "@/components/telToast.vue";
export default {
  components: {
    telToast,
  },
  data() {
    return {
      show: false,
      list: [
        "根据您的专业、研究方向、预期目标以及学校老师指导意见，确定题目",
        "根据题目查找相关文献资料和制作调查问卷，完善研究目的与意义，进行国内外文献综述撰写，同时确定研究方法，技术路线，研究内容等，完成开题报告和文献综述的撰写和修改",
        "根据学校老师要求，撰写任务书，将任务书内容确定之后，经学校老师审核后进行文章初稿撰写",
        "根据要求进行数据收集和整理，资料进一步确定，将文章各个章节进行完善",
        "根据学校要求，按照格式模板进行整理内容，使格式与学校要求基本一致",
        "文章初稿完成后，等待学校老师批复意见，并向您讲解文章框架及内容，让您熟知文章撰写流程及思路，与文章融会贯通",
        "按学校老师意见修改文章内容，确保内容与老师要求一致",
        "文章审核确定后，进入降重环节，我们会根据您提供的查重报告，将将文章重复率降到学校要求的标准范围内，并确保格式及内容完整性",
        "确保您在毕业期间安心无忧，愉快走完毕业流程",
        "学校答辩前5天左右，会有专业辅导老师为您提供模拟答辩辅导。老师会针对您的答辩内容进行讲解及问题模拟，对答辩内容进行完善及注意事项说明，对您的着装、语速、仪态等问题进行细致分析和指导",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.img1 {
  width: 100%;
  height: 500px;
  background: url("../../assets/image/icon66.png");
  background-size: 100% 500px;
  background-repeat: no-repeat;
  padding: 10px 0 0;
  box-sizing: border-box;
  .h1 {
    font-size: 99px;
    font-family: zihunxinquhei, zihunxinquhei-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    letter-spacing: 4.46px;
    text-shadow: 0px 3px 6px 0px rgba(159, 214, 253, 0.3);
  }
  .img_tip {
    margin: 6px auto 0;
    width: 509px;
    height: 54px;
    background: url("../../assets/image/icon101.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 27px 0 27px;
    box-sizing: border-box;
    font-size: 36px;
    font-family: zihunxinquhei, zihunxinquhei-Regular;
    font-weight: 400;
    text-align: center;
    color: #23dc5a;
    letter-spacing: 1.62px;
    text-shadow: 0px 3px 6px 0px rgba(159, 214, 253, 0.3);
  }
}
.contain {
  width: 1400px;
  height: 848px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto 50px;
  padding: 40px 20px 50px;
  box-sizing: border-box;
  .h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    .h1_top {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      div {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #007938;
        letter-spacing: 1.08px;
        margin: 0 20px;
      }

      img {
        width: 19px;
        height: 19px;
      }
    }
    .h1_bottom {
      width: 156px;
      height: 5px;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    .list_item {
      width: 256px;
      height: 237px;
      background: linear-gradient(
        220deg,
        #33da61 -60%,
        #ffffff 16%,
        #ffffff 10%
      );
      box-shadow: 0px 3px 6px 0px rgba(235, 235, 235, 0.6);
      border-radius: 6px;
      padding: 20px;
      box-sizing: border-box;
      margin-right: 20px;
      margin-bottom: 20px;
      .num {
        width: 37px;
        height: 37px;
        background: #23dc5a;
        border-radius: 2px;
        font-size: 30px;
        font-family: DIN Alternate, DIN Alternate-Bold;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
        line-height: 37px;
        margin-bottom: 20px;
      }
      .text {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #333333;
        letter-spacing: 0.7px;
      }
    }
    .list_item:nth-child(5n) {
      margin-right: 0px;
    }
  }
  .btn {
    width: 366px;
    height: 65px;
    background: linear-gradient(90deg, #23c48c, #33da61);
    border-radius: 6px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 0.9px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 97px auto 0;
    cursor: pointer;
    position: relative;
    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
}
</style>